import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Menu, X, Linkedin, Twitter, Anvil, Goal, Brain} from 'lucide-react';

// Initialize Google Analytics
ReactGA.initialize('G-8W51RNDPST'); // Replace with your actual Measurement ID


const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-[#737551] shadow-md' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
      <div className="flex-shrink-0 flex items-center">
        <img src="/tms-small-white.png" alt="Machine Shop" className="h-10 sm:h-12 md:h-14 lg:h-18 w-auto" />
      </div>
        <nav className="hidden md:flex space-x-6">
          {['Home', 'Services', 'About'].map((item) => (
            <a key={item} href={`#${item.toLowerCase()}`} className="text-[#F6F1EF] hover:text-[#EEA93A] transition duration-300">{item}</a>
          ))}
        </nav>
        <button className="md:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          {isMobileMenuOpen ? <X className="h-6 w-6 text-[#F6F1EF]" /> : <Menu className="h-6 w-6 text-[#F6F1EF]" />}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden bg-[#737551] py-4">
          {['Home', 'Services', 'About'].map((item) => (
            <a key={item} href={`#${item.toLowerCase()}`} className="block px-4 py-2 text-[#F6F1EF] hover:bg-[#F0E4DB] hover:text-[#EEA93A] transition duration-300">{item}</a>
          ))}
        </div>
      )}
    </header>
  );
};

const Hero = () => (
  <section id="home" className="bg-gradient-to-br from-[#737551] to-[#737551] py-32 pt-40">
    <div className="container mx-auto px-4 text-center">
      <img src="/tms-large.png" alt="Machine Shop" className="mx-auto mb-12 w-3/4 max-w-2xl" />
      <h1 className="text-4xl md:text-5xl font-bold text-[#252E2F] mb-4">AI Solutions Engineered for Your Business</h1>
      <p className="text-2xl md:text-3xl font-semibold text-[#F6F1EF] mb-8">We deliver measurable business value through AI</p>
      <a href="mailto:contact@themachineshop.ai" className="inline-block bg-[#EEA93A] text-[#F6F1EF] font-bold py-3 px-8 rounded-full hover:bg-[#252E2F] transition duration-300 transform hover:scale-105">
        Get in touch
      </a>
    </div>
  </section>
);

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="bg-[#F6F1EF] p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:-translate-y-1">
    <Icon className="h-12 w-12 text-[#EEA93A] mb-4" />
    <h3 className="text-xl font-semibold mb-2 text-[#252E2F]">{title}</h3>
    <p className="text-[#252E2F]">{description}</p>
  </div>
);

const Features = () => (
<section id="about" className="py-20 bg-[#F0E4DB]">
  <div className="container mx-auto px-4">
    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#252E2F]">The Machine Shop Advantage</h2>
    <div className="grid md:grid-cols-3 gap-8">
      <FeatureCard
        title="Focus on What Matters"
        description="We handle AI complexities, allowing you to concentrate on your core business. Our generative AI solutions have helped banking clients automate document processing, significantly improving speed and accuracy while reducing manual workload."
        icon={Goal}
      />
      <FeatureCard
        title="Expert ML Native Solutions"
        description="Leverage our 20+ years of expertise in production machine learning applications. From intelligent chatbots and semantic search to advanced image object detection, we deliver cloud native, scalable solutions tailored to your specific needs."
        icon={Anvil}
      />
      <FeatureCard
        title="State-of-the-Art AI Solutions"
        description="We implement cutting-edge AI technology to deliver tangible business value. Our solutions, like predictive analytics and natural language processing, have helped clients increase efficiency, reduce costs, and drive innovation."
        icon={Brain}
      />
    </div>
  </div>
</section>
);

const Card = ({ title, items }) => (
  <div className="bg-[#F0E4DB] p-8 rounded-lg shadow-lg hover:shadow-xl transition duration-300 max-w-lg w-full">
    <h3 className="text-xl font-semibold mb-4 text-[#252E2F]">{title}</h3>
    <ul className="list-disc list-inside text-[#252E2F]">
      {items.map((item, index) => (
        <li key={index} className="mb-2">{item}</li>
      ))}
    </ul>
  </div>
);

const ServiceCard = ({ title, items }) => (
  <div className="bg-[#F0E4DB] p-8 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
    <h3 className="text-2xl font-bold mb-6 text-[#252E2F]">{title}</h3>
    <ul className="space-y-4 list-disc pl-5"> {/* Added list-disc and pl-5 */}
      {items.map((item, index) => (
        <li key={index} className="pl-2"> {/* Added pl-2 for fine-tuning */}
          <span>{item}</span>
        </li>
      ))}
    </ul>
  </div>
);


const Services = () => (
  <section id="services" className="py-20 bg-[#F6F1EF]">
  <div className="container mx-auto px-4">
    <h2 className="text-3xl md:text-4xl font-bold text-center mb-6 text-[#252E2F]">AI Consulting Services</h2>
    <p className="text-center mb-12 text-gray-600 max-w-2xl mx-auto">Empowering businesses with cutting-edge AI solutions and expert guidance to drive innovation and efficiency.</p>
    
    <div className="flex justify-center mb-16">
      <div className="grid md:grid-cols-2 gap-8 max-w-5xl w-full">
        <Card 
          title="Our Expertise"
          items={[
            'Use Case Development',
            'Prompt Engineering',
            'Retrieval-Augmented Generation (RAG)',
            'Computer Vision',
            'Intelligent Document Processing',
            'MLOps',
            'Machine Learning',
            'Time Series Forecasting'
          ]}
        />
        <Card 
          title="Why Work With Us"
          items={[
            'Systematic Improvement of LLM Products',
            'Clarity on AI Tools, Frameworks, and Jargon',
            'Quickly Upskill Your Workforce',
            'Strategic Prioritization of AI Initiatives',
            'Expert Assessment of Needs and Talent Evaluation'
          ]}
        />
      </div>
    </div>

    <h3 className="text-2xl md:text-3xl font-bold text-center mb-8 text-[#252E2F]">Services from the Shop Floor</h3>
    <div className="grid md:grid-cols-2 gap-8 mb-12">
      <ServiceCard 
        title="Strategic Consulting"
        items={[
          "On-Demand Guidance: Async support via email, Slack, and meetings",
          "Growth & Efficiency: Enhance AI/ML skills and optimize processes",
          "Hiring Support: Expert insight for AI and ML recruitment",
          "Expert Network: Access to specialists across various AI fields"             
        ]}
      />
      <ServiceCard 
        title="Comprehensive Consulting"
        items={[
          "All Strategic Consulting benefits, plus:",
          "Rapid Prototyping & Research: Quick feasibility studies and prototypes",
          "Data & Production Support: Build and launch data-driven products",
          "Custom Evaluation Systems: Measure and improve LLM performance",
          "Hands-On Model Optimization: Fine-tune, train, and debug AI models",
          "Team Development: Intensive AI upskilling for your team"
        ]}
      />
    </div>
    
    <div className="text-center">
  <a
    href="mailto:contact@themachineshop.ai?subject=Consultation%20Request&body=I'm%20interested%20in%20scheduling%20a%20consultation%20for%20AI%20services."
    className="inline-block bg-[#252E2F] text-white py-3 px-8 rounded-full hover:bg-opacity-90 transition-colors duration-300 no-underline"
  >
    Schedule a Consultation
  </a>
</div>
  </div>
</section>
);


// const FeaturedContent = () => (
//   <section id="articles" className="py-20 bg-[#F0E4DB]">
//     <div className="container mx-auto px-4">
//       <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#252E2F]">Insights from the Shop Floor</h2>
//       <div className="grid md:grid-cols-3 gap-8">
//         {[
//           { title: "The Future of AI in Business", excerpt: "Explore how AI is reshaping industries and creating new opportunities for growth." },
//           { title: "Machine Learning Best Practices", excerpt: "Learn key strategies for successful ML implementation in your organization." },
//           { title: "AI Ethics and Responsible Innovation", excerpt: "Discover how to navigate the ethical challenges of AI adoption." }
//         ].map((article, index) => (
//           <div key={index} className="bg-[#F6F1EF] rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition duration-300 transform hover:-translate-y-1">
//             <img src={`/api/placeholder/400/200`} alt="Article thumbnail" className="w-full h-48 object-cover" />
//             <div className="p-6">
//               <h3 className="text-xl font-semibold mb-2 text-[#252E2F]">{article.title}</h3>
//               <p className="text-[#252E2F] mb-4">{article.excerpt}</p>
//               <a href="#" className="text-[#EEA93A] font-semibold hover:underline">Read More</a>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="text-center mt-12">
//         <button className="bg-[#EEA93A] text-[#F6F1EF] font-bold py-3 px-8 rounded-full hover:bg-[#737551] transition duration-300 transform hover:scale-105">
//           View All Articles
//         </button>
//       </div>
//     </div>
//   </section>
// );

const ClientLogos = () => (
  <section className="py-20 bg-[#F6F1EF]">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#252E2F]">Trusted by Industry Leaders</h2>
      <div className="flex flex-wrap justify-center items-center gap-8">
        {["yendo.png", "DHS.png", "toastgenie.png", "vinnistack.png","traeger.jpg"].map((logo, index) => (
          <img key={index} src={`/${logo}`} alt={`Client logo ${index + 1}`} className="h-24 md:h-32 lg:h-40 w-auto object-contain" />
        ))}
      </div>
    </div>
  </section>
);

// const Contact = () => (
//   <section id="contact" className="py-20 bg-[#F0E4DB]">
//     <div className="container mx-auto px-4">
//       <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#252E2F]">Let's Build Something Together</h2>
//       <div className="max-w-2xl mx-auto">
//         <form className="space-y-6">
//           <input type="text" placeholder="Name" className="w-full p-3 border border-[#252E2F] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEA93A] bg-[#F6F1EF] text-[#252E2F]" />
//           <input type="email" placeholder="Email" className="w-full p-3 border border-[#252E2F] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEA93A] bg-[#F6F1EF] text-[#252E2F]" />
//           <input type="text" placeholder="Company" className="w-full p-3 border border-[#252E2F] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEA93A] bg-[#F6F1EF] text-[#252E2F]" />
//           <textarea placeholder="Message" rows="4" className="w-full p-3 border border-[#252E2F] rounded-md focus:outline-none focus:ring-2 focus:ring-[#EEA93A] bg-[#F6F1EF] text-[#252E2F]"></textarea>
//           <button type="submit" className="w-full bg-[#EEA93A] text-[#F6F1EF] font-bold py-3 px-8 rounded-md hover:bg-[#737551] transition duration-300 transform hover:scale-105">
//             Send Message
//           </button>
//         </form>
//         <div className="mt-8 text-center">
//           <p className="flex items-center justify-center text-[#252E2F] mb-2">
//             <Mail className="h-5 w-5 mr-2" /> contact@themachineshop.ai
//           </p>
//         </div>
//       </div>
//     </div>
//   </section>
// );

const Footer = () => (
  <footer className="bg-[#737551] text-[#F6F1EF] py-12">
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-2xl font-bold mb-4">MACHINE SHOP</h3>
          <p className="text-[#F0E4DB]">AI solutions engineered for your business</p>
        </div>
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
          <ul className="space-y-2">
            {['Home', 'Services', 'About'].map((item) => (
              <li key={item}><a href={`#${item.toLowerCase()}`} className="text-[#F0E4DB] hover:text-[#EEA93A] transition duration-300">{item}</a></li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h4 className="text-lg font-semibold mb-4">Contact</h4>
          <p className="text-[#F0E4DB] mb-2">contact@themachineshop.ai</p>
        </div>
        <div className="w-full md:w-1/4">
          <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
          <div className="flex space-x-4">
            <a href="https://www.linkedin.com/company/the-machine-shop-ai" target="_blank" rel="noopener noreferrer" className="text-[#F0E4DB] hover:text-[#EEA93A] transition duration-300"><Linkedin /></a>
            <a href="https://x.com/machineshopai" target="_blank" rel="noopener noreferrer" className="text-[#F0E4DB] hover:text-[#EEA93A] transition duration-300"><Twitter /></a>
            {/* <a href="#" className="text-[#F0E4DB] hover:text-[#EEA93A] transition duration-300"><Facebook /></a> */}
          </div>
        </div>
      </div>
      <div className="border-t border-[#F0E4DB] mt-8 pt-8 text-center">
        <p className="text-[#F0E4DB]">&copy; 2024 The Machine Shop. All rights reserved.</p>
      </div>
      </div>
  </footer>
);

const App = () => {
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="font-sans bg-[#F6F1EF] text-[#252E2F]">
      <Header />
      <main>
        <Hero />
        <Features />
        <Services />
        <ClientLogos />
      </main>
      <Footer />
    </div>
  );
};

export default App;